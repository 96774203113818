import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from '../../components/SEO';
import Update from '../../components/DevensCampusPage/Update';
import Wrapper from '../../components/Wrapper';
import List from '../../components/DevensCampusPage/List';
import Footer from '../../components/Footer';
import Header from '../../components/BasicPage/Header';
import NotFoundPage from '../../pages/404';

const DevensUpdatesPage = ({ location, data }) => {
  const pageData = data.prismic.allDevens_updates_pages.edges.slice(0, 1).pop();

  if (!pageData || !pageData.node) {
    return <NotFoundPage />;
  }

  const {
    section,
    title,
    updates: updatesRaw,
    featured_image,
    featured_imageSharp,
    featured_embed_video,
    seo,
    secondary_topper_cta_link,
    secondary_topper_cta_label,
  } = pageData.node;
  const titleRaw = RichText.asText(title);

  const updates = updatesRaw
    .filter(update => update)
    .map(update => ({
      title: update.title,
      image: update.image,
      imageSharp: update.imageSharp,
      date: update.date,
      description: update.description,
      embedVideo: update.embed_video,
    }));

  return (
    <>
      <SEO
        title={titleRaw}
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />

      <Header
        title={title}
        image={featured_image}
        sharpImage={featured_imageSharp}
        embedVideo={featured_embed_video}
        subnav={section ? section.navigation : null}
        overline={section.section_title}
        secondaryCtaLink={secondary_topper_cta_link}
        secondaryCtaLabel={secondary_topper_cta_label}
      />

      <Wrapper>
        <List>
          {updates.map(update => (
            <Update key={update.title} {...update} />
          ))}
        </List>
      </Wrapper>

      <Footer />
    </>
  );
};

DevensUpdatesPage.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allDevens_updates_pages: PropTypes.object,
    }),
  }),
};

export const featurePhotoFragment = graphql`
  fragment FeaturePhotoQuery on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const query = graphql`
  query DevensUpdatesQuery {
    prismic {
      allDevens_updates_pages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            section {
              ... on PRISMIC_Site_section {
                section_title
                navigation {
                  label
                  link {
                    ...LinkQuery
                  }
                }
              }
            }

            title
            secondary_topper_cta_link {
              ...LinkQuery
            }
            secondary_topper_cta_label
            featured_image
            featured_imageSharp {
              ...FeaturePhotoQuery
            }
            featured_embed_video
            updates {
              title: title1
              date
              description
              image
              imageSharp {
                ...FeaturePhotoQuery
              }
              embed_video
            }
          }
        }
      }
    }
  }
`;

export default DevensUpdatesPage;
