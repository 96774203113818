import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RichText } from 'prismic-reactjs';

import Image from '../../Image';
import { contentSerializer } from '../../../utils';

import styles from './Update.module.scss';

const Update = ({ title, description, date, image, imageSharp, embedVideo }) => (
  <li className={styles.container}>
    <p className={styles.date}>{moment(date).format('MMM DD, YYYY')}</p>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.description}>
      <RichText render={description} htmlSerializer={contentSerializer} />
    </p>
    {embedVideo && embedVideo.html ? (
      <div className={styles.embedVideo} dangerouslySetInnerHTML={{ __html: embedVideo.html }} />
    ) : image ? (
      <div className={styles.image}>
        <Image image={image} sharpImage={imageSharp} className={styles.featured__image} />
      </div>
    ) : null}
  </li>
);

Update.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageSharp: PropTypes.object,
  embedVideo: PropTypes.string,
};

export default Update;
